import React from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";

import { useIntl } from "gatsby-plugin-react-intl";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  padding: 64px 0;
  height: 100%;
  gap: 30px;
  table,
  td {
    border: 1px solid black;
    padding: 0;
  }
  .wrapper {
    width: 100%;
  }

  table {
    width: 100%;
  }

  td {
    vertical-align: top;
    padding: 8px;
  }
`;

function Hero() {
  const intl = useIntl();

  const html = `${intl.formatMessage({
    id: "PrivacyPolicy",
  })}`;
  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          {html != "exist" && (
            <div
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            ></div>
          )}
          {html == "exist" && (
            <div class="wrapper">
              <header align="center">
                <h1>Política de privacidad</h1>
                <em>Fecha de entrada en vigor: 25 de mayo de 2018</em>
              </header>
              <br></br>
              <div class="content" style="font-size: 1rem;">
                <p>
                  <strong></strong>
                </p>
                <h3>
                  <strong>INFORMACIÓN GENERAL</strong>
                </h3>
                <strong></strong>
                <p></p>
                <p>
                  PDF Pro Software Inc., con domicilio social en 1383 W 8th Ave,
                  Vancouver, BC V6H 3V9, Canada, registro mercantil no.
                  700468291BC0001 (denominada en lo sucesivo <b>“nosotros”</b> o{" "}
                  <b>“nos”</b>), en su capacidad de controlador de datos con
                  respecto al procesamiento de datos personales, se compromete a
                  proteger y respetar la privacidad de sus usuarios, clientes y
                  proveedores, incluso potenciales, (en lo sucesivo singular y
                  colectivamente denominados <b>“usted”</b> o <b>“su”</b>), de
                  conformidad con las leyes aplicables de Hong Kong sobre
                  protección de datos (por ejemplo, la Ordenanza 486 de datos
                  personales de Hong Kong, en lo sucesivo denominada{" "}
                  <b>“Ley nacional”</b>) y, si usted es ciudadano de un País del
                  Espacio Económico Europeo, también de conformidad con el
                  Reglamento Europeo n.º 679/2016 (en lo sucesivo, el{" "}
                  <b>“RGPD”</b>) (en lo sucesivo, la Ley nacional y la RGPD se
                  denominarán <b>“Ley aplicable”</b>).
                </p>
                <p>
                  Esta política (en lo sucesivo, denominada{" "}
                  <b>“Política de privacidad”</b>) tiene como objetivo
                  informarle sobre nuestras prácticas relacionadas con nuestra
                  recopilación y uso de sus datos personales, ya sea a través de
                  nuestro sitio web www.pdfcreator.es(en lo sucesivo denominado
                  el <b>“sitio”</b>) o durante la ejecución de cualquiera de
                  nuestros servicios (en lo sucesivo denominados colectivamente
                  “servicios”).
                </p>
                <p>
                  <b>“Datos personales”</b> significa cualquier información
                  relacionada con una persona física identificada o
                  identificable: una persona física identificable es aquella que
                  puede identificarse, directa o indirectamente, en particular
                  por referencia a un identificador, como un nombre, un número
                  de identificación, datos de ubicación, un identificador en
                  línea o uno o más factores específicos de la identidad física,
                  fisiológica, genética, mental, económica, cultural o social de
                  esa persona natural.
                </p>
                <p>
                  Lo invitamos a leer detenidamente esta política de privacidad
                  para comprender nuestras consideraciones y prácticas con
                  respecto al procesamiento de sus datos personales.
                </p>
                <h2>SECC. 1 – DATOS PERSONALES QUE PROCESAMOS</h2>
                <p>
                  Cuando visita el sitio o cuando le proporcionamos nuestros
                  servicios, podemos recopilar los siguientes datos personales:
                </p>
                <h3>1.1. Información que le damos.</h3>
                <p>
                  Usted puede, a través de nuestro sitio, nuestros servicios u
                  otro canal de contacto (por ejemplo, correo electrónico,
                  etc.), proporcionarnos voluntariamente datos personales o
                  información y documentos que contengan datos personales. En
                  particular, dichos datos personales pueden incluir su nombre,
                  correo electrónico, dirección, número de pedido, datos
                  financieros y cualquier otra información que envíe a través de
                  nuestro servicio de atención al cliente.{" "}
                  <b>
                    Procesaremos estos datos de acuerdo con la ley aplicable y
                    en el supuesto de que se refieran a usted o a terceros que
                    lo hayan autorizado a proporcionarlos conforme a una base
                    jurídica adecuada que legitime el procesamiento en cuestión.
                    En este caso, usted actúa como controlador de datos
                    independiente, asumiendo todas las obligaciones y
                    responsabilidades relevantes de acuerdo con la ley
                    aplicable. En este sentido, renuncia, en el pleno sentido
                    del término, al derecho a todas las disputas, reclamaciones,
                    reclamaciones por daños y perjuicios debidos al
                    procesamiento, etc., que dichos terceros cuyos datos
                    personales hayan sido procesados a través de su uso del
                    sitio o los servicios incumpliendo la ley aplicable puedan
                    ejercer contra nosotros.
                  </b>
                </p>
                <h3>1.2. Datos de navegación.</h3>
                <p>
                  Los sistemas informáticos y los procedimientos de software
                  utilizados para explotar el sitio recopilan algunos datos
                  personales, cuya transmisión es una parte integral de los
                  protocolos de comunicación de Internet. Esta información no se
                  recopila para vincularla con usted, pero, por su propia
                  naturaleza, puede permitir su identificación al procesarla y
                  asociarla a datos de terceros. Entre los datos personales
                  recopilados hay: (i) direcciones IP o nombres de dominio de
                  los dispositivos utilizados por usted para conectarse al
                  sitio; (ii) el URI (identificador uniforme de recursos) de los
                  recursos solicitados; (iii) el momento de la solicitud, el
                  método utilizado para enviar la solicitud al servidor; (iv) el
                  tamaño del archivo recibido como respuesta; (v) el código
                  numérico que indica el estado de la respuesta dada por el
                  servidor (éxito, error, etc.); (vi) otros parámetros
                  relacionados con su sistema operativo y el entorno del
                  dispositivo.
                </p>
                <h3>1.3. Cookies y tecnologías similares.</h3>
                <p>
                  Podemos recopilar datos personales mediante cookies. Puede
                  encontrar más información sobre el uso de cookies y
                  tecnologías similares <a href="/cookies">aquí</a>
                </p>
                <h2>SECC. 2 – PROPÓSITOS Y BASE LEGAL DEL PROCESAMIENTO</h2>
                <h3>2.1. Propósitos.</h3>
                <p>
                  Procesaremos los datos personales proporcionados por usted
                  para los propósitos y la base jurídica especificados a
                  continuación.
                </p>
                <table cellSpacing={0}>
                  <thead>
                    <tr>
                      <td>
                        <b>Propósitos</b>
                      </td>
                      <td>
                        <b>Base jurídica</b>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Cumplir con nuestras obligaciones derivadas de cualquier
                        contrato celebrado entre usted y nosotros y
                        proporcionarle la información y los servicios que nos
                        solicitó (por ejemplo, crear y administrar su cuenta,
                        proporcionar nuestros servicios, procesar pagos y
                        responder a sus consultas, procesar la suscripción a
                        nuestro boletín informativo, etc.).
                      </td>
                      <td>
                        Este procesamiento es necesario para el cumplimiento de
                        nuestras obligaciones contractuales mutuas o llevado a
                        cabo con su consentimiento.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Comunicarnos con usted para verificar su cuenta y para
                        fines informativos y operativos (por ejemplo,
                        administración de cuentas, servicio al cliente,
                        mantenimiento del sistema), incluso mediante el envío
                        periódico de anuncios relacionados con los servicios.
                      </td>
                      <td>
                        Este procesamiento es necesario para el cumplimiento de
                        nuestras obligaciones contractuales mutuas o llevado a
                        cabo con su consentimiento.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Darle acceso a nuestros servicios de asistencia y
                        atención al cliente y que pueda comunicarse con nuestro
                        equipo.
                      </td>
                      <td>
                        Este procesamiento es necesario para el cumplimiento de
                        nuestras obligaciones contractuales mutuas, llevado a
                        cabo con su consentimiento o necesario para el
                        establecimiento, ejercicio o defensa de reclamaciones
                        legales.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Garantizar el cumplimiento de las leyes aplicables, los
                        términos y condiciones de nuestros servicios y nuestra
                        política de privacidad.
                      </td>
                      <td>
                        Este procesamiento es necesario para el cumplimiento de
                        nuestras obligaciones contractuales mutuas o para el
                        establecimiento, ejercicio o defensa de reclamaciones
                        legales.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Enviar información a terceros autorizados (por ejemplo,
                        clientes, usuarios finales, etc.).
                      </td>
                      <td>
                        Este procesamiento es necesario para el cumplimiento de
                        nuestras obligaciones contractuales mutuas o llevado a
                        cabo con su consentimiento.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Enviar materiales de marketing, así como sugerencias y
                        recomendaciones sobre nuestros servicios que puedan ser
                        de su interés.
                      </td>
                      <td>Este procesamiento se basa en su consentimiento.</td>
                    </tr>
                    <tr>
                      <td>
                        Llevar a cabo investigaciones estadísticas/análisis de
                        datos agregados o anónimos, sin identificarlo, así como
                        medir y evaluar el funcionamiento de nuestro sitio, su
                        tráfico y sus posibilidades de uso.
                      </td>
                      <td>
                        Este procesamiento no implica el procesamiento de datos
                        personales.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Cumplir con una obligación legal a la que estamos
                        sujetos o determinar, ejercer o defender un derecho ante
                        los tribunales o cuando una autoridad ejerza su
                        jurisdicción.
                      </td>
                      <td>
                        Este procesamiento es necesario para el establecimiento,
                        ejercicio o defensa de reclamaciones legales.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h3>2.2. Naturaleza voluntaria del procesamiento.</h3>
                <p>
                  Proporcionar sus datos personales para los fines mencionados
                  anteriormente es voluntario y no obligatorio. Sin embargo,
                  cualquier negativa a proporcionar cualquiera de estos datos
                  puede no permitirnos establecer o continuar una relación
                  contractual con usted, o cumplir sus solicitudes, o cumplir
                  con las obligaciones legales a las que estamos sujetos.
                </p>
                <h2>
                  SECC. 3 – QUÉ ES EL PERÍODO DE RETENCIÓN DE DATOS Y CUÁLES SON
                  LAS MEDIDAS DE SEGURIDAD QUE SE HAN TOMADO PARA LA PROTECCIÓN
                  DE SUS DATOS PERSONALES
                </h2>
                <h3>3.1. Retención de datos.</h3>
                <p>
                  Los datos personales recopilados por nosotros se procesarán
                  durante el tiempo estrictamente necesario para lograr los
                  fines mencionados anteriormente. En particular:
                </p>
                <ul style="list-style: none;">
                  <li>
                    <b>3.1.a</b> Los datos personales recopilados a través de la
                    prestación de servicios se eliminarán (o se anonimizarán
                    para fines estadísticos) después de 2 (dos) años desde la
                    finalización de los servicios o, si son anteriores, después
                    de su solicitud de cancelación;
                  </li>
                  <li>
                    <b>3.1.b.</b> Los datos personales necesarios para la
                    prestación de nuestro servicio de boletín se procesarán
                    hasta que decida cancelar la suscripción;
                  </li>
                  <li>
                    <b>3.1.c.</b> Los datos personales cuya retención sea
                    obligatoria según las leyes aplicables (por ejemplo, leyes
                    fiscales, teneduría de libros, etc.) se conservarán durante
                    un período de tiempo necesario o permitido para cumplir con
                    dichas leyes.
                  </li>
                </ul>
                <h3>3.2. Medidas de seguridad.</h3>
                <p>
                  Garantizamos el tener y continuar teniendo medidas de
                  seguridad técnicas y organizativas adecuadas y suficientes
                  para proteger sus datos personales contra la destrucción
                  accidental o ilegal o pérdida accidental, daño, alteración,
                  divulgación no autorizada o acceso, en particular cuando el
                  procesamiento implique la transmisión de datos a través de una
                  red y contra todas las demás formas ilegales de procesamiento.
                  Tenga en cuenta que ninguna medida de seguridad es perfecta o
                  impenetrable. Por lo tanto, aunque nos esforzamos por proteger
                  sus datos, no podemos garantizar que nunca se produzca un
                  acceso no autorizado, hackeo, pérdida de datos o violación de
                  datos. No obstante lo anterior, operamos con el objetivo de
                  mitigar los riesgos asociados al procesamiento de sus datos
                  personales con varias medidas, que incluyen:
                </p>
                <ul>
                  <li>
                    <b>3.2.a. Minimización de datos.</b> Solo obtenemos,
                    retenemos, procesamos y compartimos datos personales que son
                    esenciales para llevar a cabo nuestros servicios y
                    obligaciones legales: solo se recopila lo que es relevante y
                    necesario. En particular, a modo de ejemplo, nuestras
                    recopilaciones electrónicas (es decir, a través del sitio,
                    etc.) solo tienen campos que son relevantes para el
                    propósito de la recopilación y el procesamiento posterior,
                    mientras que la recopilación física (es decir, los contactos
                    cara a cara, llamadas telefónicas, etc.) es compatible con
                    secuencias de comandos y formularios internos que usan
                    campos predefinidos.
                  </li>
                  <li>
                    <b>3.2.b. Seudonimización.</b> Siempre que es posible,
                    utilizamos seudónimos para registrar y almacenar datos
                    personales de una manera que asegure que dichos datos ya no
                    puedan atribuirse a un sujeto de datos específico sin el uso
                    de información adicional distinta (es decir, identificadores
                    personales) que están protegidos mediante cifrado,
                    particiones y otras medidas técnicas y operacionales de
                    reducción de riesgos y protección de datos.
                  </li>
                  <li>
                    <b>3.2.c. Cifrado.</b> Aunque utilizamos cifrado (es decir,
                    utilizamos una clave secreta para hacer que los datos
                    personales sean indescifrables a menos que se lleve a cabo
                    el descifrado del conjunto de datos mediante dicha clave
                    asignada) como forma de seudónimo, también lo utilizamos
                    como medida secundaria de prevención de riesgos para
                    proteger los datos personales que procesamos. En particular,
                    utilizamos el cifrado a través de una clave secreta para
                    transferir datos personales o categorías especiales de
                    información a cualquier tercero y proporcionamos la clave
                    secreta en un formato diferente.
                  </li>
                  <li>
                    <b>3.2.d. Restricción de acceso.</b> Utilizamos métodos de
                    restricción a nivel de la empresa para restringir el acceso
                    a la base de nuestros procesos, sistemas y estructura, a fin
                    de garantizar que solo quienes tengan autorización o un
                    propósito relevante tengan acceso a los datos personales.
                    Los datos de categorías especiales están restringidos en
                    todos los niveles y solo nuestro personal autorizado puede
                    acceder a ellos.
                  </li>
                  <li>
                    <b>3.2.e. Ausencia de datos impresos.</b> Nunca almacenamos
                    datos personales en formato de copia impresa.
                  </li>
                </ul>
                <h2>
                  vSECC. 4 – QUIÉNES SON LOS DESTINATARIOS DE SUS DATOS
                  PERSONALES
                </h2>
                <h3>4.1. Proveedores de servicios externos o consultores.</h3>
                <p>
                  Contratamos a terceros fiables para realizar funciones y
                  proporcionarnos servicios, incluidos el alojamiento y el
                  mantenimiento, la supervisión de errores, la depuración, la
                  supervisión del rendimiento, la facturación, las relaciones
                  con los clientes, el almacenamiento y administración de bases
                  de datos y las campañas de marketing directo. Podemos
                  compartir su información personal con estos terceros, pero
                  solo en la medida necesaria para realizar estas funciones y
                  proporcionar dichos servicios. También exigimos que estos
                  terceros mantengan la privacidad y seguridad de sus datos
                  personales que procesan en nuestro nombre. En particular, sin
                  limitar lo anterior, nuestros principales proveedores de
                  servicios de terceros son:{" "}
                  <a href="https://fastspring.com/" target="_blank">
                    Fastspring
                  </a>
                  , que es nuestro proveedor de comercio electrónico),{" "}
                  <a href="https://mailchimp.com/" target="_blank">
                    Mailchimp
                  </a>
                  , que es nuestro proveedor de servicios de marketing por
                  correo),{" "}
                  <a href="https://www.zendesk.com/" target="_blank">
                    Zendesk
                  </a>
                  , que nos proporciona un sistema de solicitudes de atención al
                  cliente), y{" "}
                  <a href="https://licensespring.com/" target="_blank">
                    License Spring
                  </a>
                  , que es nuestro proveedor de servicios de información de
                  licencias).
                </p>
                <h3>4.2. Otros terceros.</h3>
                <p>
                  Podemos compartir datos personales con nuestras oficinas
                  centrales y afiliados, y socios comerciales a quienes sea
                  razonablemente necesario o deseable que proporcionemos sus
                  datos para los fines descritos en esta política de privacidad.
                  En particular, nuestro equipo de marketing se encuentra en
                  Canadá, nuestra oficina de atención al cliente se encuentra en
                  Filipinas, y nuestro departamento financiero se encuentra en
                  Suiza.
                </p>
                <h3>4.3. Terceros requeridos por leyes o autoridades.</h3>
                <p>
                  Podemos divulgar sus datos a un tercero si (i) consideramos
                  que la divulgación es razonablemente necesaria para cumplir
                  con cualquier ley, reglamento, proceso legal o solicitud
                  gubernamental (incluso para cumplir con los requisitos de
                  seguridad nacional o cumplimiento de la ley), (ii) hacer
                  cumplir nuestros acuerdos y políticas, (iii) proteger la
                  seguridad o la integridad de nuestros servicios y productos,
                  (iv) protegernos a nosotros mismos, a nuestros otros clientes
                  o al público de daños o actividades ilegales, o (v) responder
                  a una emergencia que creamos de buena fe que exige que
                  divulguemos datos para ayudar a prevenir una muerte o lesiones
                  corporales graves. Si la ley nos obliga a divulgar alguno de
                  sus datos personales, haremos todos los esfuerzos razonables
                  para notificarle sobre dicho requisito de divulgación, a menos
                  que se nos prohíba hacerlo por ley, citación u orden judicial
                  o administrativa. Además, nos oponemos a las solicitudes que
                  no creamos que se emitieron adecuadamente.
                </p>
                <h3>4.4. Datos agregados o anonimizados.</h3>
                <p>
                  También podemos hacer que ciertos datos no personales (es
                  decir, datos que se han anonimizados o agregado de una manera
                  que no permita su identificación directa) estén disponibles
                  para terceros con diversos fines, incluidos los comerciales o
                  de marketing, o para ayudar a terceros. en la comprensión de
                  los intereses, hábitos y patrones de uso de nuestros usuarios
                  para ciertos programas, contenidos, servicios, publicidades,
                  promociones y funcionalidades disponibles a través de los
                  servicios.
                </p>
                <h2>
                  SECC. 5 – DÓNDE SE PUEDEN TRANSFERIR SUS DATOS PERSONALES
                </h2>
                <p>
                  Tenemos nuestro domicilio social en Hong Kong, pero somos una
                  empresa descentralizada. Por lo tanto, los datos personales
                  que recopilamos pueden transferirse y almacenarse en
                  cualquiera de nuestros afiliados, socios o proveedores de
                  servicios mencionados en la sección 4 anterior, que puede
                  estar dentro o fuera del Espacio Económico Europeo. Al enviar
                  sus datos personales, acepta dichas transferencias. Sus datos
                  personales pueden transferirse a países que no tienen las
                  mismas leyes de protección de datos que el país en el que
                  inicialmente proporcionó la información, pero nos aseguramos
                  de que cuando transfiramos o divulguemos sus datos personales
                  a dichos países, protegeremos esa información como se describe
                  en esta política de privacidad.
                </p>
                <h2>SECC. 6 – SUS DERECHOS</h2>
                <h3>6.1. Derecho de acceso.</h3>
                <p>
                  Siempre tiene derecho a recibir confirmación de si sus datos
                  personales se están procesando o no y, cuando sea el caso,
                  acceder y recibir una copia de dichos datos personales de
                  forma inteligible. Además, también tiene derecho a recibir
                  información sobre: los fines del procesamiento; las categorías
                  de datos personales concernidos; los destinatarios (o
                  categorías de los mismos) a quienes los datos personales han
                  sido o serán divulgados; cuando sea posible, el período
                  previsto para el cual se almacenarán los datos personales o,
                  si no es posible, los criterios utilizados para determinar ese
                  período; la existencia del derecho a solicitarnos la
                  rectificación o el borrado de datos personales o la
                  restricción del procesamiento de sus datos personales u
                  oponerse a dicho procesamiento; el derecho a presentar una
                  queja ante una autoridad supervisora; la fuente de los datos
                  personales; la existencia de una toma de decisiones
                  automatizada; cuando los datos personales se transfieren a un
                  tercer país o a una organización internacional, las medidas de
                  seguridad apropiadas relacionadas con la transferencia.
                </p>
                <h3>6.2. Derecho a retirar el consentimiento.</h3>
                <p>
                  Siempre tiene derecho a retirar, en cualquier momento, su
                  consentimiento al procesamiento de sus datos personales, tanto
                  por motivos legítimos (incluso si son relevantes para el
                  propósito de la recopilación) como si el procesamiento se
                  lleva a cabo con fines de marketing directo. Lo anterior no
                  afectará a la legalidad del procesamiento basado en el
                  consentimiento antes del retiro.
                </p>
                <h3>6.3. Derecho a rectificación, borrado y restricción.</h3>
                <p>
                  Usted siempre tiene derecho a obtener de nosotros, sin
                  retrasos indebidos: la rectificación o integración de sus
                  datos personales que sean inexactos o estén incompletos; el
                  borrado de sus datos personales que hayan sido procesados
                  ilegalmente o cuya retención sea innecesaria para los
                  propósitos; la restricción de procesamiento, en caso de que
                  impugne la exactitud de sus datos personales o la legalidad
                  del procesamiento, o en caso de que ya no necesite los datos
                  personales para los propósitos, pero sean necesarios para el
                  establecimiento, ejercicio o defensa de una reclamación legal.
                </p>
                <h3>6.4. Derecho a la portabilidad de los datos.</h3>
                <p>
                  Tiene derecho a recibir sus datos personales en un formato
                  estructurado, comúnmente utilizado y que permita la lectura
                  automática, así como también el derecho a transmitir esos
                  datos a otro controlador sin impedimentos por nuestra parte,
                  cuando sea técnicamente factible.
                </p>
                <h3>
                  6.5. Derecho a presentar una queja ante una autoridad de
                  control europea.
                </h3>
                <p>
                  En caso de que sea ciudadano europeo, tiene derecho a
                  presentar una queja ante la autoridad supervisora ubicada en
                  su lugar de residencia.
                </p>
                <h3>6.6. Contactos.</h3>
                <p>
                  Las solicitudes para ejercer los derechos anteriores se deben
                  enviar a PDF Pro Software Inc., Suite 1701-02, 17/F, FWD
                  Financial Centre, 308 Des Voeux Road Central, Hong Kong, o por
                  correo electrónico a{" "}
                  <a href="mailto:contact@pdfpro10.com">contact@pdfpro10.com</a>
                  . Cualquier solicitud de acceso siempre se completa en un
                  periodo de un mes; sin embargo, cuando la recuperación o
                  provisión de información es particularmente compleja o está
                  sujeta a un retraso válido, el período puede extenderse por
                  dos meses más. Si este es el caso, escribiremos al individuo
                  en un plazo de un mes y lo mantendremos informado del retraso
                  y sus razones. Si no es de un país del Espacio Económico
                  Europeo, puede completar el formulario de solicitud de acceso
                  a los datos de la ley nacional (disponible en ENLACE A{" "}
                  <a
                    href="http://www.judiciary.gov.hk/en/crt_services/pphlt/pdf/jud39e.pdf"
                    target="_blank"
                  >
                    http://www.judiciary.gov.hk/en/crt_services/pphlt/pdf/jud39e.pdf
                  </a>
                  ) y enviárselo a los contactos que aparecen arriba.
                </p>
                <h2>SECC. 7 - ENMIENDAS A ESTA POLÍTICA</h2>
                <p>
                  Esta política de privacidad entró en vigor en la fecha
                  especificada anteriormente. Nos reservamos el derecho de
                  modificar o actualizar su contenido, ya sea en su totalidad o
                  en parte, también si se producen cambios en las obligaciones
                  legales y reglamentarias relacionadas con la protección de
                  datos. Le informaremos sobre dichas modificaciones y
                  actualizaciones a través de su publicación en el sitio tan
                  pronto como se adopten, y serán vinculantes desde el momento
                  de su publicación. Por lo tanto, lo invitamos a visitar esta
                  sección del sitio con regularidad, para estar al tanto de la
                  versión más reciente y actualizada de la misma, para que
                  siempre esté actualizado sobre las actividades de
                  procesamiento que llevamos a cabo.
                </p>
              </div>
            </div>
          )}
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Hero;
